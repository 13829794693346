<template>
  <div class="container">
    <div class="container" v-for="item in list2" :key="item.id">
      <div class="box">
        <div class="top">
          <h3>
            {{ item.name }}
            <img
              style="
                width: 3.125rem;
                vertical-align: middle;
                display: inline-block;
                margin-top: -0.625rem;
              "
              :src="item.img"
              alt=""
            />
          </h3>
          <p>
            {{ item.content }}
          </p>
          <a class="down" v-if="item.a && item.id != '03'" :href="link">下载</a>
          <p v-if="item.id == '03'" style="line-height: 5rem">
            <a style="" class="down" :href="link2">MacOS下载</a><br />
            <a class="down" :href="link">Windows下载</a>
          </p>
        </div>
      </div>
      <div style="width: 29rem; margin-left: 3.125vw; overflow: hidden">
        <img
          style="
            width: 24.1667vw;
            min-width: 12.5rem;
            height: auto;
            overflow: hidden;
          "
          :src="item.imgUrl"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import { getDownload } from "../../../api/home/index";
export default {
  data() {
    return {
      list: [
        {
          id: "01",
          img: require("../../../assets/mock/browser/图像 83@2x.png"),
          imgUrl: require("../../../assets/mock/browser/图像 93@2x.png"),
          content:
            "宇起智能密码器设备专用浏览器插件，下载安装宇起谷歌浏览器插件后，添加网站账号可实现指纹快速登录功能",
          name: "宇起谷歌浏览器插件",
          a: "https://www.yuqidata.com/app/app_test/y05/odobmlbgkooiliffigpakceichecmhem.crx",
        },
        {
          id: "02",
          img: require("../../../assets/mock/browser/图像 84@2x.png"),
          imgUrl: require("../../../assets/mock/browser/图像 93@2x.png"),
          content:
            "宇起智能密码器设备专用浏览器插件，安装宇起火狐浏览器插件后，添加网站账号可实现指纹快速登录功能",
          name: "宇起火狐浏览器插件",
        },
        {
          id: "03",
          img: require("../../../assets/mock/browser/蒙版组 28@2x.png"),
          imgUrl: require("../../../assets/mock/browser/图像 93@2x.png"),
          content:
            "智能指纹密码器专用电脑客户端，下载安装宇起电脑客户端后，用于实现指纹快速登录电脑、电脑客户端应用、网站账号的功能。",
          name: "宇起助手",
          a: "https://www.yuqidata.com/app/app_test/y05/yuqi-helper.exe",
        },
        {
          id: "04",
          img: require("../../../assets/mock/browser/04.png"),
          imgUrl: require("../../../assets/mock/browser/图像 93@2x.png"),
          content:
            "宇起智能密码器设备专用浏览器插件，下载安装宇起Edge浏览器插件后，添加网站账号可实现指纹快速登录功能",
          name: "宇起Edge浏览器插件",
          a: "https://www.yuqidata.com/app/app_test/y05/odobmlbgkooiliffigpakceichecmhem.crx",
        },
      ],
      list2: [],
      id: "",
      link: "",
      link2: "",
    };
  },

  watch: {
    $route(newRoute) {
      if (newRoute.query.id !== null) {
        this.id = newRoute.query.id;
        this.list2 = this.list
          .filter((obj) => obj.id == this.id)
          .map((obj) => obj);
        this.getDown();
      }
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getList();
    this.getDown();
    this.getDown2();
  },
  methods: {
    getList() {
      this.list2 = this.list
        .filter((obj) => obj.id == this.id)
        .map((obj) => obj);
    },
    getDown() {
      if (this.id) {
        var type = "";
        if (this.id == "01") {  
          type = "101";
        } else if (this.id == "02") {
          type = "102";
        } else if (this.id == "04") {
          type = "103";
        } else if (this.id == "03") {
          type = "201";
        } else if (this.id == "03") {
          type = "202";
        }
      }
      getDownload(type).then((res) => {
        this.link = res.data.data.link;
      });
    },
    getDown2() {
      getDownload("202").then((res) => {
        this.link2 = res.data.data.link;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 62.5vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  // align-content: center;
  align-items: center;
  .box {
    width: 50%;
    h3 {
      font-size: 2.6042vw;
      min-inline-size: 1.875rem;
      vertical-align: middle;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    p {
      width: 25vw;
      font-size: 1.5rem;
      color: #333;
      font-weight: 500;
      margin: 2.5rem 0;
    }
  }
  .down {
    font-size: 1.5rem;
    padding: 0.5625rem 3.6875rem;
    background-color: #3bb49d;
    color: #fff;
    border-radius: 0.5625rem;
  }
}
</style>
